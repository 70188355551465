import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import Register from "../components/Register";

const REGISTER = gql`
  mutation Register($username: String!) {
    register(user: { username: $username }) {
      username
    }
  }
`;

export default class extends Component {
  state = {
    birthday: "",
    country: "",
    description: "",
    gender: "",
    interests: "",
    password: "",
    username: ""
  };

  setValue = ({ target: { name, value } }) => {
    this.setState({
      [name]: value
    });
  };

  render = () => (
    <Mutation mutation={REGISTER}>
      {register => (
        <Register
          register={register}
          setValue={this.setValue}
          {...this.state}
        />
      )}
    </Mutation>
  );
}
