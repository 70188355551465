import React from "react";
import map from "emoji-name-map";

const Emoji = ({ name, ...props }) => {
  const emoji = map.get(name);

  return emoji ? (
    <span aria-label={name} role="img" {...props}>
      {emoji}
    </span>
  ) : null;
};

export default Emoji;
