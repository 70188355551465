import React, { Component } from "react";
import { button, form, field, input } from "./Login.module.css";

export default class extends Component {
  login = e => {
    e.preventDefault();

    const { login, password, username } = this.props;

    login({
      variables: {
        username,
        password
      }
    });
  };

  render = () => {
    const { fields, setValue, ...props } = this.props;

    return (
      <form className={form} onSubmit={this.login}>
        {fields.map(({ id, label, type }) => (
          <label className={field} htmlFor={id} key={id}>
            <input
              className={input}
              id={id}
              onChange={setValue}
              placeholder={label}
              type={type}
              value={props[id]}
            />
          </label>
        ))}

        <button className={button} type="submit">
          Login
        </button>
      </form>
    );
  };
}
