import React, { PureComponent } from "react";
import style from "./Polygon.module.css";

class Polygon extends PureComponent {
  trueOrFalse = () => Math.random() >= 0.5;

  minMax = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  randomize = () => {
    const { height, width } = this.props;
    const leftOrRight = this.minMax(1, height / 2);
    const leftZero = this.trueOrFalse();

    return [
      {
        x: 0,
        y: leftZero ? 0 : leftOrRight
      },
      {
        x: width,
        y: leftZero ? leftOrRight : 0
      },
      {
        x: this.minMax(width * 0.75, width),
        y: leftZero ? this.minMax(height * 0.75, height) : height
      },
      {
        x: this.minMax(0, height),
        y: leftZero ? height : this.minMax(height * 0.75, height)
      }
    ].reduce(
      (d, { x, y }, index, { length }) =>
        `${d ? `${d} ` : ""}${index ? "L" : "M"}${x} ${y}${
          index === length - 1 ? " Z" : ""
        }`,
      ""
    );
  };

  addToChildren = (child, polygon) =>
    React.cloneElement(child, {
      children: [polygon, ...React.Children.toArray(child.props.children)],
      className: [child.props.className, style.content].join(" ")
    });

  pushPolygon = (child, index) =>
    this.addToChildren(
      child,
      <svg
        className={style.polygon}
        key={`polygon-${index}`}
        preserveAspectRatio="none"
        viewBox={`0 0 ${this.props.width} ${this.props.height}`}
      >
        <path fill={this.props.color} d={this.randomize()} />
      </svg>
    );

  render = ({ children } = this.props) =>
    React.Children.map(children, this.pushPolygon);
}

Polygon.defaultProps = {
  children: [],
  color: "#00000040".replace(/0/g, () => (~~(Math.random() * 16)).toString(16)),
  height: 125,
  width: 250
};

export default Polygon;
