import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import Login from "./Login";
import News from "./News";
import Register from "./Register";

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/register/" component={Register} />
      <Route path="/login/" component={Login} />
      <PrivateRoute path="/news" component={News} />
    </Switch>
  </BrowserRouter>
);

export default Router;
