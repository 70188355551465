import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import loggedIn from "../helpers/loggedIn";

export default class PrivateRoute extends Component {
  handleRoute = () => {
    const { location, component: Result, ...props } = this.props;

    return loggedIn() ? (
      <Result {...props} />
    ) : (
      <Redirect
        component={Component}
        to={{
          pathname: "/login/",
          state: { from: location }
        }}
      />
    );
  };

  render = () => <Route render={this.handleRoute} />;
}
