import React, { Component } from "react";
import style from "./Register.module.css";
import { countries, genders, interests } from "../../constants";
import Date from "../Date";
import Emoji from "../Emoji";
import Radio from "../Radio";
import Select from "../Select";

const fields = {
  birthday: {
    emoji: "balloon",
    label: "When's your b-day?",
    placeholder: "Can I come to your party?",
    type: "date"
  },
  country: {
    emoji: "earth_americas",
    label: "Where do you live?",
    placeholder: "Country rooooads",
    type: "select",
    values: countries
  },
  description: {
    emoji: "sparkles",
    label: "Tell me about yourself!",
    placeholder:
      "What's up, I'm Jared, I'm 19, and I never learned how to read",
    type: "textarea"
  },
  gender: {
    emoji: "id",
    label: "How do you ID? ",
    placeholder: "(gender or no gender with howie mandel)",
    type: "radio",
    values: genders
  },
  interests: {
    count: 3,
    emoji: "sparkling_heart",
    label: "What do you like?",
    placeholder: "random",
    type: "select",
    values: interests
  },
  password: {
    emoji: "shushing",
    label: "Pick a secret...",
    placeholder: "(password... shh...)",
    type: "password"
  },
  username: {
    emoji: "raised_hands",
    label: "Make a name for yourself!",
    placeholder: "random username",
    type: "text"
  }
};

export default class extends Component {
  register = e => {
    e.preventDefault();

    this.props.register({
      variables: this.props
    });
  };

  getInput = ([id, { count = 1, emoji, label, placeholder, type, values }]) => {
    const { setValue, ...props } = this.props;

    let result;
    switch (type) {
      case "select":
        result = (
          <Select
            className={style.input}
            name={id}
            placeholder={placeholder}
            setValue={setValue}
            value={props[id]}
            values={values}
          />
        );
        break;
      case "radio":
        result = (
          <Radio
            className={style.label}
            name={id}
            placeholder={placeholder}
            setValue={setValue}
            value={props[id]}
            values={values}
          />
        );
        break;
      case "textarea":
        result = (
          <textarea
            className={style.input}
            name={id}
            onChange={setValue}
            placeholder={placeholder}
          />
        );
        break;
      case "date":
        result = (
          <Date
            className={style.input}
            name={id}
            onChange={setValue}
            placeholder={placeholder}
            type="text"
            value={props[id]}
          />
        );
        break;
      default:
        result = (
          <input
            className={style.input}
            name={id}
            onChange={setValue}
            placeholder={placeholder}
            type={type}
            value={props[id]}
          />
        );
        break;
    }

    return (
      <label className={style.field} key={id}>
        <span className={style.label}>
          {label} <Emoji name={emoji} />
        </span>

        {result}
      </label>
    );
  };

  render = () => (
    <form className={style.form} method="post" onSubmit={this.login}>
      {Object.entries(fields).map(this.getInput)}

      <button className={style.button} type="submit">
        Register
      </button>
    </form>
  );
}
