export default [
  "3D printing",
  "Acting",
  "Action figures",
  "Aerobatics",
  "Airsoft",
  "American football",
  "Animals",
  "Antiquing",
  "Aquariums",
  "Archery",
  "Art collecting",
  "Arts",
  "Astrology",
  "Astronomy",
  "Aviation",
  "Backpacking",
  "Baseball",
  "Basketball",
  "Beach volleyball",
  "Beekeeping",
  "Bicycling",
  "Birdwatching",
  "Blacksmithing",
  "Blogging",
  "Board games",
  "Boating",
  "Bodybuilding",
  "Boxing",
  "Breakdancing",
  "Bungee jumping",
  "Cake decorating",
  "Camping",
  "Canoeing",
  "Card collecting",
  "Cartooning",
  "Cheerleading",
  "Chess",
  "Cloud watching",
  "Coin collecting",
  "Collecting",
  "Coloring",
  "Computer programming",
  "Cooking",
  "Cosplay",
  "Crafts",
  "Creative writing",
  "Crocheting",
  "Crossword puzzles",
  "Cryptography",
  "Dancing",
  "Debate",
  "Digital art",
  "Dominoes",
  "Drama",
  "Drawing",
  "Electronics",
  "Entertaining",
  "Fashion",
  "Fishing",
  "Flower arranging",
  "Football",
  "Games",
  "Geocaching",
  "Geology",
  "Ghost hunting",
  "Go Kart Racing",
  "Guitar",
  "Gymnastics",
  "Horseback riding",
  "Hunting",
  "Ice hockey",
  "Ice skating",
  "Internet",
  "Inventing",
  "Jigsaw puzzles",
  "Jogging",
  "Karate",
  "Kayaking",
  "Kite flying",
  "Knitting",
  "Lacrosse",
  "Laser tag",
  "Learning",
  "Magic",
  "Meditation",
  "Meteorology",
  "Motocross",
  "Motorcycling",
  "Movies",
  "Mushroom hunting",
  "Music",
  "Musical instruments",
  "Nail art",
  "Origami",
  "Other",
  "Painting",
  "Paragliding",
  "Parkour",
  "Photography",
  "Piano",
  "Protesting",
  "Programming",
  "Quilting",
  "Radio",
  "Rallying",
  "Rapping",
  "Reading",
  "Record collecting",
  "Relaxing",
  "Robotics",
  "Roleplaying",
  "Running",
  "Scrapbooking",
  "Sculpting",
  "Shopping",
  "Skateboarding",
  "Sketching",
  "Skydiving",
  "Soccer",
  "Stamp collecting",
  "Storm chasing",
  "Storytelling",
  "Sudoku",
  "Surfing",
  "Swimming",
  "Sword fighting",
  "TV watching",
  "Tennis",
  "Tetris",
  "Texting",
  "Toy collecting",
  "Video games",
  "Volleyball",
  "Volunteering",
  "Web surfing",
  "Weightlifting",
  "Worldbuilding",
  "Wrestling",
  "Writing music",
  "Writing",
  "Yoga",
  "Zumba"
];
