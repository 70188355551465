import React, { Component } from "react";

class Date extends Component {
  setElementRef = el => (this.el = el);

  setDateType = () => (this.el.type = "date");

  setTextType = () => (this.el.type = "text");

  render = () => (
    <input
      onFocus={this.setDateType}
      onBlur={this.setTextType}
      ref={this.setElementRef}
      type="text"
      {...this.props}
    />
  );
}

export default Date;
