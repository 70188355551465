import ApolloClient from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "./styles/screens.css";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";

const client = new ApolloClient({
  link: createHttpLink({
    credentials: "include",
    fetch,
    uri: process.env.REACT_APP_API
  }),
  cache: new InMemoryCache()
});

ReactDOM.render(<App client={client} />, document.getElementById("root"));
serviceWorker.unregister();
