import React, { PureComponent } from "react";

class Radio extends PureComponent {
  getInput = (value, index) => {
    const { className, name, setValue } = this.props;
    const idIndex = `${name}${index}`;

    return (
      <label className={className} htmlFor={idIndex} key={value}>
        <span>{value}</span>{" "}
        <input
          id={idIndex}
          name={name}
          onChange={setValue}
          type="radio"
          value={value}
        />
      </label>
    );
  };

  render = ({ className, placeholder, value, values } = this.props) => (
    <>
      {!value && <span className={className}>{placeholder}</span>}
      {values.map(this.getInput)}
    </>
  );
}

Radio.defaultProps = {
  values: []
};

export default Radio;
