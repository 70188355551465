import React from "react";
import { ApolloProvider } from "react-apollo";
import { app, title } from "./App.module.css";
import Polygon from "../Polygon";
import Router from "../../containers/Router";

export default ({ client }) => (
  <ApolloProvider client={client}>
    <main className={app}>
      <Polygon>
        <h1 className={title}>nanopets</h1>
      </Polygon>

      <Router />
    </main>
  </ApolloProvider>
);
