import React, { Component } from "react";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import Login from "../components/Login";

const LOGIN = gql`
  mutation Login($password: String!, $username: String!) {
    login(user: { password: $password, username: $username }) {
      token
    }
  }
`;

export default class extends Component {
  init = (all, { id }) => ({ ...all, [id]: "" });

  fields = [
    {
      id: "username",
      label: "Username",
      type: "text"
    },
    {
      id: "password",
      label: "Password",
      type: "password"
    }
  ];

  state = this.fields.reduce(this.init, {});

  setValue = ({ target: { id, value } }) =>
    this.setState({
      [id]: value
    });

  setToken = (
    cache,
    {
      data: {
        login: { token }
      }
    }
  ) => {
    sessionStorage.setItem("token", token);
    // maybe update to render Redirect component later
    this.props.history.push("/news");
  };

  render = ({ fields, state } = this) => (
    <Mutation mutation={LOGIN} update={this.setToken}>
      {login => (
        <Login
          fields={fields}
          login={login}
          setValue={this.setValue}
          {...state}
        />
      )}
    </Mutation>
  );
}
