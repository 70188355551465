import React, { PureComponent } from "react";

class Select extends PureComponent {
  getOption = value => (
    <option key={value} value={value}>
      {value}
    </option>
  );

  render = (
    { className, name, placeholder, setValue, value, values } = this.props
  ) => (
    <select className={className} name={name} onChange={setValue} value={value}>
      <option disabled value="">
        {placeholder}
      </option>

      {values.map(this.getOption)}
    </select>
  );
}

Select.defaultProps = {
  values: []
};

export default Select;
